<template>
  <div class="diagnosis-page">
    <van-nav-bar left-arrow fixed left-text="返回" title="医疗诊断" @click-left="$router.go(-1)"></van-nav-bar>
    <div class="container">
      <div class="form-container">
        <van-form @submit="onSubmit">
          <van-field
            v-model="formData.cavity"
            name="cavity"
            class="variable-width"
            placeholder="请输入腹腔诊断情况（限制20个字）"
            maxlength="20"
          >
            <template #label>
              <span>腹腔</span>：
            </template>
          </van-field>
          <van-field
            v-model="formData.up_fron"
            name="up_fron"
            class="variable-width"
            placeholder="请输入上颚软口盖诊断情况（限制20个字）"
            maxlength="20"
          >
            <template #label>
              <span>上颚软口盖</span>：
            </template>
          </van-field>
          <van-field
            v-model="formData.be_fron"
            name="be_fron"
            class="variable-width"
            placeholder="请输入下颚诊断情况（限制20个字）"
            maxlength="20"
          >
            <template #label>
              <span>下颚</span>：
            </template>
          </van-field>
          <van-field
            v-model="formData.lip"
            name="lip"
            class="variable-width"
            placeholder="请输入唇部诊断情况（限制20个字）"
            maxlength="20"
          >
            <template #label>
              <span>唇部</span>：
            </template>
          </van-field>
          <van-field
            v-model="formData.tongue"
            name="tongue"
            class="variable-width"
            placeholder="请输入舌头诊断情况（限制20个字）"
            maxlength="20"
          >
            <template #label>
              <span>舌头</span>：
            </template>
          </van-field>
          <van-field
            readonly
            :value="snagTxt"
            name="is_snag"
            class="variable-width"
            placeholder="请选择是否有动作障碍"
            @click-input="pickerShow(1)"
          >
            <template #label>
              <span>动作障碍</span>：
            </template>
            <template #right-icon>
              <span class="icon">&#x25BC;</span>
            </template>
          </van-field>
          <van-field
            v-model="formData.muscle"
            class="muscle"
            name="muscle"
            placeholder="请输入初始肌肉或肌张力状况（限制50个字）"
            maxlength="50"
          >
            <template #label>
              <span>初始肌肉或肌张力状况</span>：
            </template>
          </van-field>
          <van-field
            readonly
            :value="oralTxt"
            name="sta_oral"
            placeholder="请选择口腔感知情况"
            @click-input="pickerShow(2)"
          >
            <template #label>
              <span>口腔感知</span>：
            </template>
            <template #right-icon>
              <span class="icon">&#x25BC;</span>
            </template>
          </van-field>
          <van-field name="accept" label="请勾选以下可以接受的：" class="checkbox-group">
            <template #input>
              <van-checkbox-group
                v-model="checkboxValue"
                direction="horizontal"
                checked-color="#F14947"
                @change="checkboxChange"
              >
                <van-checkbox
                  shape="square"
                  v-for="item in projectList"
                  :key="item.key"
                  :name="item.key"
                >{{ item.name }}</van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>
          <van-field
            v-model="formData.critique"
            name="critique"
            label="评论："
            class="textarea-box"
            type="textarea"
            maxlength="100"
            autosize
            placeholder="请输入评论内容（限制100个字）"
          />
          <div class="btn">
            <van-button color="#F14947" native-type="submit">确定</van-button>
          </div>
        </van-form>
      </div>
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="[[],columns1,columns2][pickerType]"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPicker: false,
      pickerType: 0,
      formData: {},
      snagTxt: '',
      oralTxt: '',
      columns1: [
        { value: 1, text: '有', },
        { value: 2, text: '没有' }
      ],
      columns2: [
        { value: 1, text: '低敏' },
        { value: 2, text: '高敏' },
        { value: 3, text: '混合' },
        { value: 4, text: '波动' },
        { value: 5, text: '触觉防御' },
        { value: 6, text: '正常' }
      ],
      projectList: [
        { key: 1, name: '双手触碰' },
        { key: 2, name: '感知豆袋' },
        { key: 3, name: 'Z-vibe震荡棒：绿色咀嚼头（开）' },
        { key: 4, name: 'Z-vibe震荡棒：绿色咀嚼头（关）' },
        { key: 5, name: 'Z-vibe震荡棒：黄色咀嚼头（开）' },
        { key: 6, name: 'Z-vibe震荡棒：黄色咀嚼头（关）' },
        { key: 7, name: '海绵棒' },
        { key: 8, name: '海绵棒加震动器' },
        { key: 9, name: '肥皂泡吹气步骤' },
      ],
      checkboxValue: []
    }
  },
  created() {
    this.initData()
  },
  methods: {
    async initData() {
      let params = {
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
      }
      let res = await this.$http.get('must-diacrisis/get', { params })
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.formData = res.data
      if (this.formData.is_snag > 0) {
        let item = this.columns1.find(e => e.value === this.formData.is_snag * 1)
        this.snagTxt = item ? item.text : ''
      }
      if (this.formData.sta_oral > 0) {
        let item = this.columns2.find(e => e.value === this.formData.sta_oral * 1)
        this.oralTxt = item ? item.text : ''
      }
      if (this.formData.accept && this.formData.accept.length > 0) {
        this.checkboxValue = this.formData.accept.map(e => e.key)
      }
    },
    pickerShow(type) {
      this.pickerType = type;
      this.showPicker = true
    },
    onConfirm(value) {
      if (this.pickerType === 1) {
        this.formData.is_snag = value.value;
        this.snagTxt = value.text;
      }
      if (this.pickerType === 2) {
        this.formData.sta_oral = value.value;
        this.oralTxt = value.text;
      }
      this.showPicker = false
    },
    checkboxChange(e) {
      this.formData.accept = []
      this.checkboxValue.forEach(i => {
        let item = this.projectList.find(e => e.key === i)
        if (item) {
          this.formData.accept.push({
            key: item.key,
            name: item.name
          })
        }
      })
    },
    async onSubmit() {
      let params = {
        ...this.formData,
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
        ass_time: this.$route.query.ass_time,
        time: this.$route.query.time,
        accept: this.formData.accept && this.formData.accept.length > 0 ? JSON.stringify(this.formData.accept) : ''
      }
      const res = await this.$http.post('must-diacrisis/add', params);
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.$vantnotify({ type: 'success', message: res.msg });
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.diagnosis-page {
  background: url(~@/assets/img/bj.png) top left/100% auto no-repeat #f8f8f8;
  height: 100vh;
  padding-top: 0.46rem;
  box-sizing: border-box;
  button {
    border: none;
  }
  .van-nav-bar {
    background: rgba(255, 255, 255, 0.3);
    &::after {
      border: none;
    }
    /deep/ .van-nav-bar__content {
      height: 0.46rem;
    }
    /deep/ .van-nav-bar__left {
      font-size: 0.14rem;
      .van-nav-bar__text {
        color: #666;
      }
      .van-icon {
        font-size: 0.14rem;
        color: #666;
        margin: 0;
      }
    }
    /deep/ .van-nav-bar__title {
      font-size: 0.16rem;
    }
  }
  .container {
    padding: 0.2rem;
    height: calc(100vh - 0.46rem);
    overflow-y: scroll;
    box-sizing: border-box;
    .form-container {
      background: #fff;
      padding: 0.16rem 0.16rem 0.2rem;
      border-radius: 0.06rem;
      .van-cell:not(.checkbox-group) {
        background: none;
        padding: 0.1rem 0;
        &::after {
          border: none;
        }
        /deep/ .van-field__label {
          line-height: 0.36rem;
          font-size: 0.16rem;
          color: #333;
          margin: 0;
          span {
            display: inline-block;
            width: 83%;
            text-align: justify;
            text-align-last: justify;
            line-height: 0;
            &:after {
              content: "";
              width: 100%;
              height: 0;
              display: inline-block;
              visibility: hidden;
            }
          }
        }
        /deep/ .van-field__value {
          background: #fffcfc;
          border-radius: 0.06rem;
          border: 1px solid #ffecec;
          input {
            height: 0.36rem;
            padding: 0.1rem 0.14rem;
          }
          .icon {
            color: #fe6765;
          }
        }
        /deep/ .van-field__right-icon {
          margin: 0;
        }
      }
      .van-cell.muscle {
        /deep/ .van-field__label {
          width: 12em;
          span {
            width: 90%;
          }
        }
      }
      .van-cell.checkbox-group,
      .van-cell.textarea-box {
        display: block;
        background: none;
        padding: 0.1rem 0 0;
        &::after {
          border: none;
        }
        /deep/ .van-field__label {
          width: 100%;
          font-size: 0.16rem;
          color: #333;
          font-weight: bold;
        }
        .van-checkbox {
          margin: 0.1rem 0.5rem 0.1rem 0;
          /deep/ .van-checkbox__icon {
            font-size: 0.16rem;
            .van-icon {
              border-radius: 0.04rem;
            }
          }
          /deep/ .van-checkbox__label {
            font-size: 0.14rem;
            color: #666;
          }
        }
      }
      .van-cell.textarea-box {
        padding-top: 0;
        /deep/ .van-field__label {
          span {
            text-align: left;
            text-align-last: left;
          }
        }
        /deep/ textarea {
          padding: 0.05rem 0.1rem;
        }
      }
      .btn {
        width: 100%;
        text-align: center;
        margin-top: 0.3rem;
        button {
          width: 1rem;
          height: 0.38rem;
          border-radius: 0.06rem;
          font-size: 0.16rem;
        }
      }
    }
  }
  /deep/ .van-picker__confirm{
    color: #fe6765;
  }
}
@media only screen and (orientation: landscape) {
  form {
    display: flex;
    flex-wrap: wrap;
  }
  .diagnosis-page
    .container
    .form-container
    .variable-width:not(.checkbox-group) {
    width: 50%;
    padding: 0.1rem 0;
    &:nth-child(even) {
      padding-left: 0.1rem;
    }
    &:nth-child(odd) {
      padding-right: 0.1rem;
    }
  }
}
</style>